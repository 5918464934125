$(document).ready(function(){

    /*
     *  REQUIRED for responsive menu
     *  add button to open submenus
     *  ****************************
     */
    $('#mobileNavigation .mobileMainMenu li').each(function(){
        if($(this).children('ul').length > 0){
            $(this).children(":first").prepend('<span class="openSubMenu"></span>');
            $(this).addClass('hasChild');
        }
    });
    
    $(document).on('click', '.mobileMainMenu .openSubMenu', function(e){

        e.preventDefault();

        var parentLi = $(this).closest("li");
        var auntLi = parentLi.siblings("li");

        // close submenus
        auntLi.find("ul").slideUp();
        parentLi.find("ul").slideUp();
        
        if(!parentLi.find("ul:first").is(":visible")){
            auntLi.find(".subMenuOpen").removeClass('subMenuOpen');
            $(this).addClass('subMenuOpen');
            parentLi.find("ul:first").slideDown();
        } else {
            parentLi.find(".subMenuOpen").removeClass('subMenuOpen');
        }
        
    });
    
});

$(document).ready(function(){

    function fixIeObjectFit(){

        // Detect objectFit support
        if(document.createElement("detect").style.objectFit === ""){
            document.getElementsByTagName("html")[0].className += " objectFit";
            return;

        } else {
            $('.overflowImage').each(function(){
                var img    = $(this).find('img');
                var imgURL = img.attr('src');
                img.hide();
                $(this).css('background-image', 'url(' + imgURL + ')');
                $(this).css('background-size', 'cover');
                $(this).css('background-position', 'center center');
            });

        }
    }

    fixIeObjectFit();

});

