$(document).ready(function(){

    $("#contactForm").validate({
                                   rules: {
                                       name: "required",
                                       email: {
                                           required: true,
                                           email: true
                                       },
                                       user_message: "required",
                                       captcha: "required"
                                   },
                                   messages: {
                                       name: "Required",
                                       email: {
                                           email: "name@domain.com",
                                           required: "Required"
                                       },
                                       user_message: "Required",
                                       captcha: "Required"
                                   }
                               });

    $("#shippingOptions").validate({
                                       rules: {
                                           shipping_option: "required"
                                       },
                                       messages: {
                                           shipping_option: "Please select an option."
                                       },
                                       errorPlacement: function(error, element){
                                           $('.validationErrorContainer').html('<div class="validationError">'+error.html()+'</div>');
                                       }
                                   });

});

$(document).ready(function(){



    /*
    * submit a form on select option change
    */
    $('.submitOnChange').on('change', function(){
        form = $(this).closest("form");
        form.submit();
    });
        
    $('.gotoOnChange').on('change', function(){
        url = $(this).val();
        window.location.href = url;
    });
    
    /*
    * disable links
    */
    $(".disabled").on('click', function(e){
        e.preventDefault();
    });


    $("#indexSlides").on('cycle-update-view', function(e, optionHash, slideOptionsHash, currSlideEl){

        if(optionHash.currSlide == (optionHash.slideCount - 1)){
            $("#slidesNextTitle").html("Next - " + $(".cycle-slide:first-child").data("title"));
        } else {

            $("#slidesNextTitle").html("Next - " + $(currSlideEl).next().data("title"));
        }
    });

    $('#playSlides').click(function(e){
        $("#indexSlides").cycle('resume');
        $(this).hide();
        $('#pauseSlides').show();
    });

    $('#pauseSlides').click(function(e){
        $("#indexSlides").cycle('pause');
        $(this).hide();
        $('#playSlides').show();
    });

});

